<template>
	<div class="badge-section">
		<div class="title-container-badge">
			<span class="section-title">Badges</span>
			<v-tooltip color="black">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-on="on" v-bind="attrs">
						<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
					</v-btn>
				</template>
				<span>Débloque des badges en fonction du nombre d'exercices faits</span>
			</v-tooltip>
		</div>
		<div class="badge-div">
			<div class="badge-container">
				<!-- 1 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 1" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge1.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge1.png" />
				</div>

				<!-- 5 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 5" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge5.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge5.png" />
				</div>

				<!-- 10 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 10" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge10.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge10.png" />
				</div>

				<!-- 30 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 30" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge30.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge30.png" />
				</div>

				<!-- 50 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 50" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge50.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge50.png" />
				</div>

				<!-- 80 exercices fait -->
				<div v-if="nbreUniqueExerciceDone >= 80" class="badge">
					<img class="badge-img" src="@/assets/images/badges/badge80.png" />
				</div>
				<div v-else class="badge-grey">
					<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
					<img class="img-badge-locked" src="@/assets/images/badges/badge80.png" />
				</div>

				<!-- 100 exercices fait -->
				<div v-if="formationProgress.config.formular > 1">
					<div v-if="nbreUniqueExerciceDone >= 100" class="badge">
						<img class="badge-img" src="@/assets/images/badges/badge100.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-badge-locked" src="@/assets/images/badges/badge100.png" />
					</div>
				</div>

				<!-- 160 exercices fait -->
				<div v-if="formationProgress.config.formular >= 2">
					<div v-if="nbreUniqueExerciceDone >= 160" class="badge">
						<img class="badge-img" src="@/assets/images/badges/badge160.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-badge-locked" src="@/assets/images/badges/badge160.png" />
					</div>
				</div>

				<!-- 200 exercices fait -->
				<div v-if="formationProgress.config.formular > 2">
					<div v-if="nbreUniqueExerciceDone >= 200" class="badge">
						<img class="badge-img" src="@/assets/images/badges/badge200.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-badge-locked" src="@/assets/images/badges/badge200.png" />
					</div>
				</div>

				<!-- 240 exercices fait -->
				<div v-if="formationProgress.config.formular > 2">
					<div v-if="nbreUniqueExerciceDone >= 240" class="badge">
						<img class="badge-img" src="@/assets/images/badges/badge240.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-badge-locked" src="@/assets/images/badges/badge240.png" />
					</div>
				</div>

				<!-- Streak > 2 jours -->
				<div>
					<div v-if="streak.highest_streak >= 2" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak2.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak2.png" />
					</div>
				</div>
				<!-- Streak > 5 jours -->
				<div>
					<div v-if="streak.highest_streak >= 5" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak5.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak5.png" />
					</div>
				</div>
				<!-- Streak > 10 jours -->
				<div>
					<div v-if="streak.highest_streak >= 10" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak10.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak10.png" />
					</div>
				</div>
				<!-- Streak > 20 jours -->
				<div>
					<div v-if="streak.highest_streak >= 20" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak20.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak20.png" />
					</div>
				</div>
				<!-- Streak > 40 jours -->
				<div>
					<div v-if="streak.highest_streak >= 40" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak40.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak40.png" />
					</div>
				</div>
				<!-- Streak > 50 jours -->
				<div>
					<div v-if="streak.highest_streak >= 50" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak50.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak50.png" />
					</div>
				</div>
				<!-- Streak > 70 jours -->
				<div>
					<div v-if="streak.highest_streak >= 70" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak70.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak70.png" />
					</div>
				</div>
				<!-- Streak > 100 jours -->
				<div>
					<div v-if="streak.highest_streak >= 100" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak100.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak100.png" />
					</div>
				</div>
				<!-- Streak > 200 jours -->
				<div>
					<div v-if="streak.highest_streak >= 200" class="badge">
						<img class="badge-img streak" src="@/assets/images/badges/streak200.png" />
					</div>
					<div v-else class="badge-grey">
						<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
						<img class="img-streak-locked" src="@/assets/images/badges/streak200.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ProfileBadge',
	data() {
		return {};
	},
	computed: {
		...mapGetters('profile', {
			exercicesInfos: 'exercicesInfos',
			formationProgress: 'formationProgress',
		}),
		...mapGetters('streak', { streak: 'streak' }),
		nbreUniqueExerciceDone() {
			return this.exercicesInfos.length;
		},
	},
	async created() {
		if (this.streak == null && this.profileInfos._id) {
			this.$store.dispatch('streak/searchStreakByUserId', { userId: this.profileInfos._id });
		}
	},
};
</script>

<style lang="scss" scoped>
.badge-section {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	.title-container-badge {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}
.section-title {
	font-size: 20px;
	font-weight: 700;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
}

.badge-div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.badge-container {
	display: grid;
	grid-template-columns: 100px 100px 100px 100px 100px;
	grid-row: auto auto;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	.badge {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}
	.badge-img {
		width: 150px;
	}
	.streak {
		width: 100px;
	}
	.badge-grey {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: rgba(128, 128, 128, 0.229);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;

		.img-badge-locked {
			position: absolute;
			opacity: 0.1;
			width: 150px;
		}
		.img-streak-locked {
			position: absolute;
			opacity: 0.1;
			width: 100px;
		}
		.icon-badge-locked {
			position: absolute;
		}
	}

	@media screen and (max-width: 600px) {
		grid-template-columns: 100px 100px 100px;
		grid-column-gap: 1vh;
		grid-row-gap: 1vh;
	}
}
</style>
