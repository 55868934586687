<template>
	<div class="stats-section">
		<span class="section-title">Statistiques</span>
		<div class="statistic-container">
			<div class="box">
				<StreakInProfileStatistics />
			</div>

			<div class="box">
				<div class="pictureDiv">🗓️</div>
				<div class="numberDiv">
					<div class="titleNumber">{{ nbreUniqueExerciceDone }}</div>
					<div class="subTitleNumber">
						<span v-if="nbreUniqueExerciceDone > 0">exercices faits</span><span v-else>exercice fait</span>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="pictureDiv">👀</div>
				<div class="numberDiv">
					<div class="titleNumber">{{ nbreRulesView }}</div>
					<div class="subTitleNumber">
						<span v-if="nbreRulesView > 0">règles vues</span><span v-else>règle vue</span>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="pictureDiv">🌟</div>
				<div class="numberDiv">
					<div class="titleNumber">{{ nbRulesMemorized }}</div>
					<div class="subTitleNumber">
						<span v-if="nbRulesMemorized > 0">règles mémorisées</span><span v-else>règle mémorisée</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import StreakInProfileStatistics from '@/components/streak/StreakInProfileStatistics.vue';

export default {
	name: 'ProfileStats',
	components: { StreakInProfileStatistics },
	data() {
		return {
			nbRulesMemorized: 0,
		};
	},
	computed: {
		...mapGetters('profile', {
			exercicesInfos: 'exercicesInfos',
			rulesUser: 'rulesUser',
		}),
		nbreRulesView() {
			return this.rulesUser.length;
		},
		nbreUniqueExerciceDone() {
			return this.exercicesInfos.length;
		},
	},
	mounted() {
		this.countMemorizedRules(this.rulesUser);
	},
	methods: {
		countMemorizedRules(rulesUser) {
			rulesUser.map((rule) => {
				if (rule.score >= 150) this.nbRulesMemorized++;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.stats-section {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}
.section-title {
	font-size: 20px;
	font-weight: 700;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
}

.statistic-container {
	display: grid;
	grid-template-columns: 300px 300px;
	grid-row: auto auto;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	.box {
		display: flex;
		flex-direction: row;
		justify-content: left;
		padding: 40px;
		border-radius: 8px;
		box-shadow: 0 1px 7px 0 rgba(129, 137, 169, 0.2) !important;

		.pictureDiv {
			font-size: 30px;
			margin-right: 10px;
		}

		.numberDiv {
			text-align: left;

			.titleNumber {
				font-size: 30px;
				font-weight: 700;
			}

			.subTitleNumber {
				color: grey;
			}
		}
	}
	@media screen and (max-width: 600px) {
		display: flex;
		flex-direction: column;

		.box {
			width: 100% !important;
		}
	}
}
</style>
